import React, { useRef } from 'react';
import { Swiper } from 'swiper/react';
import { useRouter } from 'next/router';
import SwiperCore, {
  Navigation,
  Autoplay,
  EffectCube,
  EffectFade,
} from 'swiper';
import { getDirection } from '@utils/get-direction';
import type { NavigationOptions } from 'swiper/types/components/navigation';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import BannerProductButtons from '@components/banner-product-buttons';

SwiperCore.use([Navigation, Autoplay, EffectCube, EffectFade]);

type CarouselPropsType = {
  className?: string;
  buttonClassName?: string;
  buttonSize?: 'default' | 'small';
  paginationVariant?: 'default' | 'circle' | 'hero' | 'banner' | 'hidden';
  centeredSlides?: boolean;
  breakpoints?: any;
  pagination?: any;
  navigation?: any;
  children?: React.ReactNode;
  autoplay?: any;
  effect?: any;
  articleId?: string;
};

const Carousel: React.FunctionComponent<CarouselPropsType> = ({
  children,
  effect,
  className = '',
  buttonClassName = '',
  buttonSize = 'default',
  paginationVariant = 'default',
  breakpoints,
  articleId,
  autoplay = {
    delay: 8000,
  },
  ...props
}) => {
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  const { locale } = useRouter();
  const dir = getDirection(locale);

  return (
    <div
      className={`carouselWrapper relative ${className} ${
        paginationVariant === 'circle' ? 'dotsCircle' : ''
      }`}
    >
      <Swiper
        loop={true}
        autoplay={{ delay: 8000 }}
        breakpoints={breakpoints}
        dir={dir}
        effect={effect}
        cubeEffect={{
          shadow: false,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94,
        }}
        navigation={{
          prevEl: prevRef.current ? prevRef.current : undefined,
          nextEl: nextRef.current ? nextRef.current : undefined,
        }}
        onInit={(swiper: SwiperCore): void => {
          const navigation = swiper.params.navigation as NavigationOptions;
          navigation.prevEl = prevRef.current;
          navigation.nextEl = nextRef.current;
          swiper.update();
        }}
        {...props}
      >
        {children}
      </Swiper>

      {paginationVariant === 'hidden' && null}

      {paginationVariant === 'hero' && (
        <div className="flex items-center absolute bottom-0 right-0 z-20">
          <button
            ref={nextRef}
            aria-label="next-button"
            className="absolute bottom-0 right-0 h-16 w-16 lg:h-20 bg-opacity-90 hover:bg-opacity-100 transition-all duration-300 text-xl lg:text-2xl lg:w-20 bg-telesoft text-heading flex justify-center items-center"
          >
            <BsChevronRight />
          </button>
          <button
            ref={prevRef}
            aria-label="prev-button"
            className="absolute bottom-0 right-16 lg:right-20 bg-opacity-90 hover:bg-opacity-100 transition-all duration-300 text-xl lg:text-2xl h-16 w-16 lg:h-20 lg:w-20 bg-white text-heading flex justify-center items-center"
          >
            <BsChevronLeft />
          </button>
        </div>
      )}

      {paginationVariant === 'banner' && <BannerProductButtons />}

      {paginationVariant === 'default' && (
        <div className="flex items-center w-full absolute top-2/4 z-10">
          <button
            ref={prevRef}
            aria-label="prev-button"
            className={`${buttonClassName} ${
              buttonSize === 'default'
                ? 'w-7 h-7 md:w-7 md:h-7 lg:w-9 lg:h-9 xl:w-10 xl:h-10 3xl:w-12 3xl:h-12 text-sm md:text-base lg:text-xl 3xl:text-2xl'
                : 'w-7 h-7 md:w-7 md:h-7 lg:w-8 lg:h-8 text-sm md:text-base lg:text-lg'
            } text-black flex items-center justify-center text-gray-0 bg-white absolute transition duration-250 hover:bg-heading hover:text-white focus:outline-none start-0 transform ${
              dir === 'rtl'
                ? 'rotate-180 shadow-header translate-x-1/2'
                : 'shadow-header -translate-x-1/2'
            }`}
          >
            <BsChevronLeft />
          </button>
          <button
            ref={nextRef}
            aria-label="next-button"
            className={`${buttonClassName} ${
              buttonSize === 'default'
                ? 'w-7 h-7 lg:w-9 lg:h-9 xl:w-10 xl:h-10 3xl:w-12 3xl:h-12 text-sm md:text-base lg:text-xl 3xl:text-2xl'
                : 'w-7 h-7 lg:w-8 lg:h-8 text-sm md:text-base lg:text-lg'
            } text-black flex items-center justify-center bg-white absolute transition duration-250 hover:bg-gray-900 hover:text-white focus:outline-none end-0 transform ${
              dir === 'rtl'
                ? 'rotate-180 shadow-header -translate-x-1/2'
                : 'shadow-header translate-x-1/2'
            }`}
          >
            <BsChevronRight />
          </button>
        </div>
      )}

      {paginationVariant === 'circle' && (
        <div className="flex items-center w-full absolute top-2/4 z-10">
          <button
            ref={prevRef}
            aria-label="prev-button"
            className={`${buttonClassName} ${
              buttonSize === 'default'
                ? 'w-7 h-7 md:w-7 md:h-7 lg:w-9 lg:h-9 xl:w-10 xl:h-10 3xl:w-12 3xl:h-12 text-sm md:text-base lg:text-xl 3xl:text-2xl'
                : 'w-7 h-7 md:w-7 md:h-7 lg:w-8 lg:h-8 text-sm md:text-base lg:text-lg'
            } text-black flex items-center justify-center text-gray-0 bg-white absolute transition duration-250 hover:bg-heading hover:text-white focus:outline-none start-0 transform ${
              dir === 'rtl'
                ? 'rotate-180 shadow-header translate-x-1/2'
                : 'shadow-header -translate-x-1/2'
            }`}
          >
            <BsChevronRight />
          </button>
          <button
            ref={nextRef}
            aria-label="next-button"
            className={`${buttonClassName} ${
              buttonSize === 'default'
                ? 'w-7 h-7 lg:w-9 lg:h-9 xl:w-10 xl:h-10 3xl:w-12 3xl:h-12 text-sm md:text-base lg:text-xl 3xl:text-2xl'
                : 'w-7 h-7 lg:w-8 lg:h-8 text-sm md:text-base lg:text-lg'
            } text-black flex items-center justify-center bg-white absolute transition duration-250 hover:bg-heading hover:text-white focus:outline-none end-0 transform ${
              dir === 'rtl'
                ? 'rotate-180 shadow-header -translate-x-1/2'
                : 'shadow-header translate-x-1/2'
            }`}
          >
            <BsChevronLeft />
          </button>
        </div>
      )}
    </div>
  );
};

export default Carousel;
