import React, { ReactElement } from 'react';

interface Props {
  name: string;
  labelKey: string;
}

function FieldLabel({ name, labelKey }: Props): ReactElement {
  return (
    <label
      htmlFor={name}
      className="block text-gray-600 uppercase font-terminal font-medium tracking-wide text-sm leading-none mb-0 mt-2 cursor-pointer"
    >
      {labelKey}
    </label>
  );
}

export default FieldLabel;
