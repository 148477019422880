import axios from 'axios';

export async function checkIfHuman(ref: any) {
  const token = await ref.current!.executeAsync();
  ref.current!.reset();
  const response = await axios({
    method: 'POST',
    url: '/api/auth/human',
    data: {
      token,
    },
  });
  const isHuman = await response.data;
  if (!isHuman) {
    return false;
  }
  return true;
}
