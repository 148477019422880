import cn from 'classnames';
import React, { InputHTMLAttributes } from 'react';
import FieldLabel from './field-label';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  inputClassName?: string;
  labelKey?: string;
  placeholderKey?: string;
  name: string;
  errorKey?: string;
  type?: string;
  shadow?: boolean;
  variant?: 'normal' | 'solid' | 'outline' | 'light';
}
const classes = {
  root: 'py-2 pl-0 pr-4 md:pr-5 w-full appearance-none relative transition text-sm lg:text-base duration-150 ease-in-out border-b placeholder-body min-h-12 transition duration-200 ease-in-out',
  normal: ' border-heading focus:shadow focus:border-telesoft text-input ',
  solid:
    ' border-heading focus:outline-none focus:border-telesoft h-11 md:h-12 text-input',
  light:
    ' border-white focus:outline-none text-white placeholder-body-dark focus:border-telesoft h-11 md:h-12',
  outline: 'border-heading focus:border-telesoft text-input ',
  shadow: 'focus:shadow',
};
const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className = 'block',
      labelKey,
      name,
      errorKey,
      placeholderKey,
      variant = 'normal',
      shadow = false,
      type = 'text',
      inputClassName,
      ...rest
    },
    ref
  ) => {
    const rootClassName = cn(
      classes.root,
      {
        [classes.normal]: variant === 'normal',
        [classes.solid]: variant === 'solid',
        [classes.outline]: variant === 'outline',
        [classes.light]: variant === 'light',
      },
      {
        [classes.shadow]: shadow,
      },
      inputClassName
    );
    return (
      <div className={`relative ${className}`}>
        {labelKey && <FieldLabel name={name} labelKey={labelKey} />}
        <input
          id={name}
          name={name}
          type={type}
          ref={ref}
          // @ts-ignore
          placeholder={placeholderKey}
          className={rootClassName}
          autoComplete="off"
          spellCheck="false"
          aria-invalid={errorKey ? 'true' : 'false'}
          {...rest}
        />
        {errorKey && <p className="my-2 text-xs text-red-500">{errorKey}</p>}
      </div>
    );
  }
);

Input.displayName = 'Input';
export default Input;
