import axios from 'axios';
import toast from 'react-hot-toast';
import { formStore } from '../store/form-store';
import { checkIfHuman } from './checkIfHuman';
import { API_URL } from './constants';

export type TFormFields = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string | number;
  company: string;
  companySize?: string | number;
  message: string;
  contactPreference: string;
  newletter: boolean;
  source?: string;
  id: string;
  formName?: string;
  severity?: string;
  severityLevel?: string;
  newsletter?: boolean;
  region?: string;
  jobTitle?: string;
  description?: string;
  software?: string;
  productName?: string;
  hardware?: string;
  serial?: string;
  createdAt?: string;
  updatedAt?: string;
};

type TEndpoint =
  | 'form-submissions'
  | 'contact-requests'
  | 'support-requests'
  | 'demo-leads'
  | 'content-leads'
  | 'newsletter-subscribers'
  | 'landing-page-leads';

export async function onSubmitForm(
  formName: string,
  ref: any,
  fields: TFormFields,
  endpoint: TEndpoint,
  setIsSubmitted: (prev: boolean) => void,
  reset: () => any
) {
  formStore.isLoading = true;
  toast.loading('Sending submission...', {
    id: 'SEND',
  });

  let isHuman = true;
  if (ref !== false) {
    isHuman = await checkIfHuman(ref);
  }
  if (!isHuman) {
    toast.error(
      "We can't verify the authenticity of this request. Please try submitting the form once more.",
      { id: 'RECAPTCHA' }
    );
  }

  const { firstName, lastName, email, phone, company } = fields;

  formStore.fields = {
    ...formStore.fields,
    firstName,
    lastName,
    email,
    phone,
    company,
  };

  try {
    await axios({
      method: 'POST',
      url: API_URL + '/' + endpoint,
      data: {
        ...fields,
        formName,
        raw: JSON.stringify(fields),
      },
    });
  } catch (e) {
    console.log(e);
    formStore.isLoading = false;
    toast.error(
      "We couldn't authenticate this request. Please try again or call us directly.",
      { id: 'FORM' }
    );
  }
  formStore.isLoading = false;
  setIsSubmitted(true);
  reset();
  toast.success('Submission sent successfully.', {
    id: 'FORM',
  });
}
