export const ROUTES = {
  HOME: '/',
  PRODUCTS: `/products`,
  PRODUCT: `/product`,
  SOLUTIONS: '/solutions',
  ARTICLES: '/blog',
  ARTICLE: '/article',
  CONTACT: '/contact-us',
  DEMONSTRATION: '/demonstration',
  TERMS: '/terms-and-conditions',
  PRIVACY: '/privacy-policy',
  COLLECTIONS: '/collections',
  CATEGORY: '/content',
  ASSETS: process.env.NEXT_PUBLIC_ASSETS_URL,
};
