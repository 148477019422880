import React, { ReactElement } from 'react';
import { IoMdReturnRight } from 'react-icons/io';
import Grid from './grid';
import Container from './ui/container';
import Text from './ui/text';

function ProductButtonItem({ text }: { text: string }) {
  return (
    <button
      type="button"
      className="group col-span-4 sm:col-span-2 flex flex-col w-full md:col-span-4 justify-start items-start opacity-50 hover:opacity-100 transition-all duration-300"
    >
      <div className="flex justify-start items-center">
        <IoMdReturnRight className="mr-3" />
        <Text variant="headingLight" className="text-white font-medium">
          {text}
        </Text>
      </div>
      <div className="h-[1px] bg-white w-full mt-2 group-hover:bg-telesoft"></div>
    </button>
  );
}

export default function BannerProductButtons(): ReactElement {
  return (
    <Container>
      <Grid className="relative invisible xs:visible bottom-44 sm:bottom-32 lg:bottom-20 items-stretch z-10 text-white">
        <ProductButtonItem text="TDAC Suite" />
        <ProductButtonItem text="CERNE Detect" />
        <ProductButtonItem text="FlowProbe Ingest" />
      </Grid>
    </Container>
  );
}
