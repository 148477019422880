import { proxy } from 'valtio';

type TFormStore = {
  isLoading: boolean;
  fields: {
    firstName: string | null | undefined;
    lastName: string | null | undefined;
    email: string | null | undefined;
    jobTitle: string | null | undefined;
    phone: string | number | null | undefined;
    company: string | null | undefined;
  };
};

const formStore = proxy<TFormStore>({
  isLoading: false,
  fields: {
    firstName: null,
    lastName: null,
    email: null,
    jobTitle: null,
    phone: null,
    company: null,
  },
});

export { formStore };
